import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import innovapost from "../images/innovapost.webp";
import worldvision from "../images/worldvision.png";
import nrt from "../images/nrt.png";
import community from "../images/community.png";
import wordjack from "../images/wordjack.png";
import xcg from "../images/xcg.png";

const Customers = () => {
    return (
        <section className="customers">
            <Container className="customers__container">
                <Row>
                    <Col className="customers__col">
                        <div className="heading">Customers Proudly Served</div>
                    </Col>
                </Row>
                <div className="space-4"></div>
                <Row>
                    <Col className="customers__col--logos">
                        <Image
                            className="customers__logo"
                            src={innovapost}
                            fluid={true}
                        />
                        <Image
                            className="customers__logo"
                            src={community}
                            fluid={true}
                        />
                    </Col>
                    <Col className="customers__col--logos">
                        <Image
                            className="customers__logo"
                            src={worldvision}
                            fluid={true}
                        />

                        <Image
                            className="customers__logo"
                            src={xcg}
                            fluid={true}
                        />
                    </Col>
                    <Col className="customers__col--logos">
                        <Image
                            className="customers__logo"
                            src={nrt}
                            fluid={true}
                        />
                        <Image
                            className="customers__logo"
                            src={wordjack}
                            fluid={true}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Customers;
