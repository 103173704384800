import React from "react";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet'
import Img from "gatsby-image";
import Footer from "../components/Footer";
import Customers from "../components/Customers";
import OurValue from "../components/OurValue";
import ContactForm from "../components/ContactForm";
import NavBar from "../components/NavBar";
import { Container, Row, Col, Image } from "react-bootstrap";
import KeepInformed from "../components/KeepInformed";
import ImageMeta from '../components/common/meta/ImageMeta'
import config from '../utils/siteConfig'
import coverImage from '../images/kubernetes.png'
const pageTitle = "More Secure Infrastructure Consolidation on Kubernetes";
const pageDescription = config.siteDescriptionMeta;
const pageUrl = config.siteUrl + "/Infrastructure";

const Infrastructure = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <link rel="canonical" href={pageUrl} />

                <meta property="og:site_name" content={config.siteName} />
                <meta property="og:type" content="page" />
                <meta property="og:title"
                    content={pageTitle}
                />
                <meta property="og:description"
                    content={pageDescription}
                />
                <meta property="og:url" content={pageUrl} />
                {/* <meta property="article:published_time" content={ghostPost.published_at} />
                <meta property="article:modified_time" content={ghostPost.updated_at} />
                {publicTags.map((keyword, i) => (<meta property="article:tag" content={keyword} key={i} />))}
                {author.facebookUrl && <meta property="article:author" content={author.facebookUrl} />} */}

                <meta name="twitter:title"
                    content={pageTitle}
                />
                <meta name="twitter:description"
                    content={pageDescription}
                />
                <meta name="twitter:url" content={pageUrl} />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="more_secure" />
                {/* {primaryTag && <meta name="twitter:label2" content="Filed under" />}
                {primaryTag && <meta name="twitter:data2" content="MoreSecure" />}

                {settings.twitter && <meta name="twitter:site" content={`https://twitter.com/${settings.twitter.replace(/^@/, ``)}/`} />}
                {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script> */}
            </Helmet>
            <ImageMeta image={coverImage} />
            <NavBar />
            <header className="header">
                <Container className="header__container">
                    <Row className="header__row">
                        <Col
                            xm={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="header__left-col"
                        >
                            <div className="header-wrapper">
                                <div className="main-sub-heading">
                                    Infrastructure Consolidation on
                                </div>
                                <div className="main-heading">Kubernetes</div>
                                <KeepInformed />
                            </div>
                        </Col>
                        <Col className="header__right-col">
                            <Img
                                className="header__infra-image"
                                fluid={data.dockerImage.childImageSharp.fluid}
                                alt="Docker Image"
                            />
                        </Col>
                    </Row>
                </Container>
                <svg
                    className="header__svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                >
                    <path
                        fill="#fff"
                        fillOpacity="1"
                        d="M0,96L60,90.7C120,85,240,75,360,90.7C480,107,600,149,720,149.3C840,149,960,107,1080,101.3C1200,96,1320,128,1380,144L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                    ></path>
                </svg>
            </header>
            <section className="infra">
                <div className="infra__blob-one"></div>
                <div className="infra__blob-two"></div>
                <div className="infra__blob-three"></div>
                <Container className="infra__container">
                    <Row className="infra__row">
                        <Col className="infra__col">
                            <div className="heading">
                                Traditional Infrastructure Problems for Digital
                                Transformation
                            </div>
                        </Col>
                    </Row>
                    <Row className="infra__row">
                        <Col lg={6} xl={6} className="infra__col-left">
                            <Img
                                fluid={data.roadImage.childImageSharp.fluid}
                                alt="Road Image"
                            />
                        </Col>
                        <Col lg={6} xl={6} className="homepage__col-right">
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Lack of standard process
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Hard to maintain consistence
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Expensive proprietary solutions
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">Vendor locked-in</div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Hard to adopt the cloud strategy
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Not remote working friendly
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Security is often after-thought
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="infra__row">
                        <Col className="infra__col">
                            <div className="heading">
                                Kubernetes Benefit for Your Business
                            </div>
                        </Col>
                    </Row>
                    <Row className="infra__row">
                        <Col xl={12} className="infra__col-left">
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Kubernetes is a portable, extensible,
                                    open-source platform for managing
                                    containerized infrastructure from Google.
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Kubernetes enables business faster time to
                                    market with improved application development
                                    and deployment efficiency.
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Kubernetes can improve your business
                                    application scalability, availability, and
                                    security with built-in features.
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Kubernetes can help business build
                                    standard-based on-premise, cloud, and hybrid
                                    cloud solutions, business can migrate
                                    seamlessly between different environments.
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Business can save costs on Kubernetes
                                    infrastructure.
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Kubernetes has a large, rapidly growing
                                    ecosystem. Kubernetes services, support, and
                                    tools are widely available.
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Kubernetes combines over 15 years of
                                    Google's experience running production
                                    workloads at scale with best-of-breed ideas
                                    and practices from the community.
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="infra__row">
                        <Col className="infra__col-kubernetes">
                            <Img
                                fluid={
                                    data.kubernetesImage.childImageSharp.fluid
                                }
                                alt="Kubernetes Image"
                            />
                        </Col>
                    </Row>
                    <Row className="infra__row">
                        <Col className="infra__col">
                            <div className="heading">
                                Why Choose More Secure Computing
                            </div>
                        </Col>
                    </Row>
                    <Row className="infra__row">
                        <Col lg={5} xl={5} className="infra__col-left">
                            <Img
                                fluid={data.upgradeImage.childImageSharp.fluid}
                                alt="Upgrade Image"
                            />
                        </Col>
                        <Col lg={7} xl={7} className="infra__col-right">
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Top industry experts in infrastructure
                                    services to build on-premise, cloud, and
                                    hybrid solutions.
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    Deep understanding and hands-on experience
                                    of Kubernetes to build your solutions with
                                    the right fit for your business needs.
                                </div>
                            </div>
                            <div className="text-wrapper">
                                <div className="dot-wrapper">
                                    <div className="dot" />
                                </div>
                                <div className="text">
                                    We have built highly efficient
                                    infrastructure solutions from physical,
                                    virtual, container, and orchestration
                                    technologies in the last 20+ years.
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <OurValue />
            <Customers />
            <ContactForm />
            <Footer />
        </>
    );
};

export default Infrastructure;

export const query = graphql`
    query {
        dockerImage: file(relativePath: { eq: "docker.png" }) {
            childImageSharp {
                fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        kubernetesImage: file(relativePath: { eq: "kubernetes.png" }) {
            childImageSharp {
                fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        roadImage: file(relativePath: { eq: "road.png" }) {
            childImageSharp {
                fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        upgradeImage: file(relativePath: { eq: "upgrade.png" }) {
            childImageSharp {
                fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
