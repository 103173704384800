import React, { useState, useEffect, useRef } from "react";

const KeepInformed = () => {
    const [newsLetterEmail, setNewsLetterEmail] = useState("");

    const firstRender = useRef(true);

    const [disable, setDisabled] = useState(true);

    const [newsLetterEmailError, setNewsLetterEmailError] = useState(null);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setDisabled(formValidation());
    }, [newsLetterEmail]);

    const formValidation = () => {
        if (newsLetterEmail === "") {
            setNewsLetterEmailError(
                "Please enter a valid Email, e.g. john@moresecure.com"
            );
            return true;
        } else {
            setNewsLetterEmailError(null);
            return false;
        }
    };

    const alert = useRef(null);

    const onSubmit = () => {
        let formData = new FormData();

        formData.append("mauticform[email]", newsLetterEmail);
        formData.append("mauticform[formid]", 1);
        formData.append("mauticform[formName]", "msnewslettersignup");
        formData.append("mauticform[messenger]", 1);

        fetch("https://mt.moresecure.com/form/submit?formId=1", {
            method: "post",
            body: formData,
            mode: "no-cors",
        })
            .then((data) => {
                console.log(data);
                if (alert.current) {
                    alert.current.innerText =
                        "Welcome to the newsletter, You should recieve a welcome email in 15 minutes";
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <>
            <div className="keep">
                <div
                    autoComplete="false"
                    id="mauticform_msnewslettersignup"
                    data-mautic-form="msnewslettersignup"
                    encType="multipart/form-data"
                    className="keep__form"
                >
                    <input
                        onChange={(e) => setNewsLetterEmail(e.target.value)}
                        className="keep__input"
                        id="mauticform_input_msnewslettersignup_email"
                        name="mauticform[email]"
                        value={newsLetterEmail}
                        placeholder="Your email address"
                        type="email"
                    />
                    <button
                        onClick={onSubmit}
                        disabled={disable}
                        type="submit"
                        name="mauticform[submit]"
                        id="mauticform_input_msnewslettersignup_submit"
                        className="keep__button"
                    >
                        Keep me informed
                    </button>
                </div>
            </div>
            <span ref={alert} className="keep__alert"></span>
            <span className="keep__error">{newsLetterEmailError}</span>
        </>
    );
};

export default KeepInformed;
